interface LogoIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
}

export default function LogoVerticalIcon({ width = "100%", height = 20, color = "#2E3DF4", className }: LogoIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 299 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title translate="no">Jayne</title>
      <path
        d="M268.633 19L267.273 27H289.578L286.428 45H264.133L262.773 53H289.08L285.727 72H237.399L249.947 0H298.28L294.927 19H268.633Z"
        fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M188.067 72H168.036L180.584 0H199.027L216.846 33.6585L222.786 0H242.814L230.266 72H211.824L193.912 38.2501L188.067 72Z"
            fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M140.055 72H118.026L121.49 51.9461L107.611 0H131.327L136.961 27.1393L144.85 13.5696L152.738 0H175.705L143.466 52.2606L140.055 72Z"
            fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M60.2182 72H37.3364L75.085 0H96.5283L109.283 72H85.5948L85.4959 71.1104C85.3462 69.7632 85.1965 68.4408 85.0467 67.1171L85.0455 67.1064C84.9288 66.0755 84.812 65.0438 84.6952 64H64.0189C63.4676 65.1512 62.9316 66.2874 62.3943 67.4266L62.3935 67.4283C61.769 68.7522 61.1426 70.0802 60.4908 71.4338L60.2182 72ZM72.3534 46H82.7753C82.0781 39.5188 81.5057 33.0404 81.058 26.6139C80.9533 26.85 80.8485 27.0862 80.7438 27.3224C77.9996 33.5106 75.2311 39.7537 72.3534 46Z"
            fill={color}/>
      <path
        d="M2.3319 52.1224L3.38129 52.3167C6.02379 52.8061 8.26283 53 10.4992 53C13.185 53 15.3442 52.1968 16.9893 50.5958C18.6523 48.9775 19.8864 46.4496 20.5139 42.8292L20.5144 42.8265L28.06 0H50.0935L42.5848 42.0692L42.5842 42.0725C40.8636 52.0902 37.4525 59.8369 32.1796 65.0838C26.8842 70.3531 19.8131 73 10.9992 73C7.74664 73 4.06958 72.6959 0.87894 71.9754L3.8147e-06 71.777L2.3319 52.1224Z"
        fill={color}/>
    </svg>
  );
}
