import LogoVerticalIcon from "~/component/icon/LogoVerticalIcon";
import {ReactNode} from "react";

export const Footer = () => {
  const linkClassName = "opacity-80 hover:opacity-100 hover:underline";

  return (
    <footer id="footer" className={"text-white bg-primary-background"}>
      {/*<hr className="w-full mx-auto" />*/}

      <section className="container py-20 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-x-12 gap-y-8">
        <div className="col-span-full xl:col-span-1">
          <a
            href="/"
            className="font-bold text-xl flex"
          >
            <LogoVerticalIcon color={"white"}/>
          </a>
        </div>

        <div className="flex flex-col gap-2">
          {/* TODO: */}
          <SectionTitle>Suivez nous</SectionTitle>

          <div>
            <a
              rel="noreferrer noopener"
              href="#"
              className={linkClassName}
            >
              Instagram
            </a>
          </div>

          {/*<div>*/}
          {/*  <a*/}
          {/*    rel="noreferrer noopener"*/}
          {/*    href="#"*/}
          {/*    className={linkClassName}*/}
          {/*  >*/}
          {/*    Linkedin*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>

        <div className="flex flex-col gap-2">
          <SectionTitle>Plateformes</SectionTitle>

          <div>
            <a
              href="/install"
              className={linkClassName}
            >
              Téléchargez l'app
            </a>
          </div>

          <div>
            <a
              rel="noreferrer noopener"
              href="https://dashboard.jayneapp.com"
              className={linkClassName}
            >
              Dashboard
            </a>
          </div>

        </div>

        <div className="flex flex-col gap-2">
          <SectionTitle>Liens utiles</SectionTitle>

          <div>
            <a
              href="/contact-us"
              className={linkClassName}
            >
              Nous contacter
            </a>
          </div>

          <div>
            <a
              href="/app/place"
              className={linkClassName}
            >
              Salle
            </a>
          </div>

          <div>
            <a
              href="/app/coach"
              className={linkClassName}
            >
              Coach
            </a>
          </div>

          <div>
            <a
              href="/app/customer"
              className={linkClassName}
            >
              Client
            </a>
          </div>

          <div>
            <a
              href="/faq-coach"
              className={linkClassName}
            >
              FAQ Coach
            </a>
          </div>

          <div>
            <a
              href="/faq-customer"
              className={linkClassName}
            >
              FAQ Client
            </a>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <SectionTitle>Légal</SectionTitle>

          <div>
            <a
              href="/cgu"
              className={linkClassName}
            >
              CGU
            </a>
          </div>

          <div>
            <a
              href="/legal"
              className={linkClassName}
            >
              Mentions légales
            </a>
          </div>

          <div>
            <a
              href="/privacy"
              className={linkClassName}
            >
              Politique de confidentialité
            </a>
          </div>


        </div>

      </section>

      {/*<section className="container pb-14 text-center">*/}
      {/*  <h3>*/}
      {/*    {new Date().getFullYear()} - Jayne*/}
      {/*  </h3>*/}
      {/*</section>*/}
    </footer>
  );
};

const SectionTitle = ({ children }: { children: ReactNode }) => <h3 className="font-bold text-lg">{children}</h3>;
