import {Fragment, ReactNode} from "react";
import {Navbar, NavbarProps} from "~/component/layout/Navbar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator
} from "~/component/ui/breadcrumb";
import {Link} from "@remix-run/react";
import {Footer} from "~/component/layout/Footer";

interface MainLayoutProps {
  menuItemTo: string;
  children: ReactNode;
  breadcrumbs?: Array<{
    label: string;
    to?: string;
  }>
  navbarMode?: NavbarProps["mode"];
}

export function MainLayout(
  {
    menuItemTo,
    children,
    breadcrumbs,
    navbarMode,
}: Readonly<MainLayoutProps>) {
  return (
    <div className="flex flex-col min-h-screen w-full">
        <Navbar
          menuItemTo={menuItemTo}
          mode={navbarMode}
        />

        <div className="flex flex-1">
          {breadcrumbs && (
            <Breadcrumb className="hidden md:flex">

              <BreadcrumbList>

                {breadcrumbs.map((breadcrumbData, index) => (
                  <Fragment key={breadcrumbData.label}>
                    <BreadcrumbItem>
                      {breadcrumbData.to ? <BreadcrumbLink asChild><Link
                          to={breadcrumbData.to}>{breadcrumbData.label}</Link></BreadcrumbLink>
                        : <span>{breadcrumbData.label}</span>}
                    </BreadcrumbItem>

                    {index !== breadcrumbs.length - 1 && (
                      <BreadcrumbSeparator/>
                    )}
                  </Fragment>
                ))}

              </BreadcrumbList>

            </Breadcrumb>
          )}

          <main className="flex flex-1 flex-col overflow-hidden">
            {children}
          </main>

        </div>

        <Footer />

    </div>
  )
}
